<template>
  <component :is="'div'">

    <template>
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0">
                Звʼязатись з водіями
              </h2>
              <div class="breadcrumb-wrapper">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                      class="align-text-top"
                    />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    :to="{ name: 'apps-drivers-list' }"
                    :key="'Водії'"
                  >
                    Водії
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <b-card>
      
      <b-form
          class="p-2"
          @submit.prevent="sendNotification()"
        >

        <div class="mb-1">
          <h4 class="mb-0">
            Звʼязатись з водіями
          </h4>
        </div>

        <b-form-group
          label="Водії"
          label-for="drivers"
        >
          <v-select
            v-model="drivers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="driversOptions"
            required
            :reduce="val => val.value"
            @input="changeDrivers"
          />
        </b-form-group>

        <b-form-group
          label="Тема"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="title"
            trim
          />
        </b-form-group>
        
        <b-form-group
          label="Повідомлення"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="message"
            trim
            style="height: 200px"
            required
          />
        </b-form-group>

        <b-form-checkbox
            v-model="important"
            value="important"
          >
            Важливо
        </b-form-checkbox>

        <div class="d-flex align-items-center mt-2">
          <!-- Form Actions -->
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Надіслати
            </b-button>
          </div>
          <small class="text-success" v-if="sended">Сповіщення надіслано</small>
          <small class="text-danger" v-if="error">Помилка. Сповіщення не надіслано</small>
        </div>

      </b-form> 
    </b-card>     

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BFormCheckbox
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import driverStoreModule from '../driverStoreModule'
import Ripple from 'vue-ripple-directive'
import axiosIns from "@/libs/axios";
import vSelect from 'vue-select'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,

    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      drivers: [],
      driversOptions: [],
      previousSelectedStructures: [],
    }
  },
  methods: {
    sendNotification() {

      let options = {
        profileIds: this.drivers,
        title: this.title,
        message: this.message,
        type: this.important,
      }

      if (this.drivers.indexOf('all') != -1) {
        options = {
          all: true,
          title: this.title,
          message: this.message,
          type: this.important,
        }
      }

      axiosIns.post("/business/notifications/drivers", options)
      .then(response => {
        this.sended = true
        this.error = false
      })
      .catch(error => {
        this.sended = false
        this.error = true
      });
    },
    getDrivers: async function() {

      let driversOptions = []

      const response = await axiosIns
          .get('/business/drivers')
          .then(response => {
              return response.data.items
          })
          .catch(() => {
              return false
          })

      response.forEach(driver => {
          driversOptions.push({
            label: driver.fullName,
            value: driver.profileId,
          })
      });

      return driversOptions
    },
    changeDrivers(value) {

      let added = value.filter(
        (val) => !this.previousSelectedStructures.includes(val)
      );

      this.previousSelectedStructures = value;

      if (added[0] == 'all') {
        this.drivers = ['all']
      } else {
        this.drivers = this.drivers.filter(
          (option) => option != 'all'
        )
      }
    }
  },
  async mounted() {
    this.driversOptions = [ 
      {
        label: 'Всі',
        value: 'all'
      }, 
      ... await this.getDrivers()
    ]
  },
  setup() {

    const USER_APP_STORE_MODULE_NAME = 'app-driver'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, driverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const message = ''
    const title = ''
    const important = 'normal'
    const sended = false
    const error = false
    
    return {
      message,
      title,
      important,
      sended,
      error,
    }
  },
}
</script>

<style>

</style>
